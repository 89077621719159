import React, { useState } from 'react'
import styled from 'styled-components'
import Menu from './menu'

const StyledBurger = styled.div`
    width: 2rem;
    height: 2rem;
    position: relative;
    right: 2rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 7;
    &:hover{
        cursor: pointer;
        div {
            background-color: #f1f1f1;
        }
    }

    div {
        width: 2rem;
        height: 0.25rem;
        background-color: ${({open}) => open ? '#818181' : '#e0e0e0'};
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.5s linear;

        &:nth-child(1) {
            transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        &:nth-child(2) {
            transform: ${({open}) => open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({open}) => open ? 0 : 1};
        }

        &:nth-child(3) {
            transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

const Burger = () => {
    const [open, setOpen] = useState(false)

    function handler() {
        setOpen(false)
    }

    return(
        <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div/>
                <div/>
                <div/>
            </StyledBurger>
            <Menu open={open} handler={handler}/>
        </>
    )
}

export default Burger